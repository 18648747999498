import { AdvantagesFeature } from "@/api"
import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import Image from "next/image"

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
`

const CardRow = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 16px;
  align-self: flex-start;
  margin: 10px;
`

interface CardDescriptionProps {
  description: AdvantagesFeature
}

export const CardDescription = ({ description }: CardDescriptionProps) => {
  return (
    <CardWrapper>
      <CardRow>
        <Image
          src={description.icon?.imageModel?.imageUrl}
          alt=""
          width={41}
          height={41}
        />
        <Typography variant="h3" style={{ fontSize: 18 }} pr={1}>
          {description.title}
        </Typography>
      </CardRow>
      <CardRow>
        <Typography fontSize={14} fontWeight={300}>
          {description.description}
        </Typography>
      </CardRow>
    </CardWrapper>
  )
}
