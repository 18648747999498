import { Advantage } from "@/api"
import { useIsDesktop } from "@/common/MediaQueries"
import theme from "@/theme/theme"
import styled, { CSSObject } from "@emotion/styled"
import { Button, Grid, Typography } from "@mui/material"
import { sendGTMEvent } from "@next/third-parties/google"
import Image from "next/image"
import { useEffect, useRef, useState } from "react"
import SwiperCore from "swiper"
import "swiper/css"
import { Pagination } from "swiper/modules"
import { Swiper, SwiperSlide } from "swiper/react"
import { Video } from "../../../shared/Video"
import { CardDescription } from "./CardDescription"
SwiperCore.use([Pagination])

const FrameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const VideoWrapper = styled.div`
  width: 1024px;
  margin-top: 20px;
  position: relative;
  z-index: 0;

  ${theme.breakpoints.down("md")} {
    width: 80%;
  }
`
const PouseAndPlay = styled(Button)(
  () =>
    ({
      position: "absolute",
      inset: "auto auto auto 0",
      bottom: "3%",
      zIndex: 1,
    }) as CSSObject
)

const GridWrapper = styled(Grid)(
  () =>
    ({
      width: "1024px",
      margin: "20px",
      display: "flex",
      alignItems: "flex-start",
      flexWrap: "wrap",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    }) as CSSObject
)

const StyledSwiper = styled(Swiper)(({}) => ({
  margin: 0,
  display: "flex",
  width: "100%",
  padding: "0 16px!important",
  ".swiper-wrapper": {
    padding: "56px 16px 64px",
  },

  ".swiper-pagination": {
    bottom: "0px",
  },
}))
const CustomSwiperSlide = styled(SwiperSlide)(({ theme }) => ({
  width: "100% !important",
  "&.swiper-slide-active": {
    width: "100% !important",
  },
}))

interface CardDescription {
  data: Advantage
}

export const DescriptionFrame = ({ data }: CardDescription) => {
  const [pouse, setPouse] = useState(false)
  const ref = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (pouse) {
      ref.current?.pause()
    } else {
      ref.current?.play()
    }
  }, [pouse])
  const isDesktop = useIsDesktop()
  return (
    <FrameWrapper>
      <Typography
        mt={2}
        variant="h5"
        fontSize={isDesktop ? 32 : 24}
        fontWeight={500}
      >
        {data?.title}
      </Typography>
      <Typography mt={2} fontSize={18} fontWeight={300}>
        {data?.subTitle}
      </Typography>
      <VideoWrapper>
        <Video src={data?.image?.imageUrl} ref={ref} />
        <PouseAndPlay
          variant="text"
          onClick={() => {
            sendGTMEvent({
              action: "click",
              event: "play_middle_video_clicks",
            })
            setPouse(!pouse)
          }}
        >
          <Image
            src={pouse ? "assets/pause.svg" : "assets/play.svg"}
            alt="play"
            width={30}
            height={30}
          />
        </PouseAndPlay>
      </VideoWrapper>
      <GridWrapper container spacing={2}>
        {data?.features?.features?.map((feature, i) => (
          <Grid key={i} item xs={12} sm={4}>
            <CardDescription description={feature} />
          </Grid>
        ))}
      </GridWrapper>

      {!isDesktop && (
        <StyledSwiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {data?.features?.features?.map((feature, i) => (
            <CustomSwiperSlide key={i}>
              <CardDescription description={feature} />
            </CustomSwiperSlide>
          ))}
        </StyledSwiper>
      )}
    </FrameWrapper>
  )
}
